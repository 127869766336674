import React from 'react';

const Loader = () => {
  return (
    <div id="loader-wrapper" className="pageContainer">
      <div id="loader">
        <div>
          <div className="loaderLogo" />
        </div>
      </div>

      <div className="loader-section section-left"></div>
    </div>
  );
};

export default Loader;
